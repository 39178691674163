import {  useContext } from 'react';
import UsuarioContext from '../context/usuarios/UsuarioContext.js';

import 'react-big-calendar/lib/css/react-big-calendar.css'



export default function Login() {

const {getUsuario} = useContext(UsuarioContext)

  return (
    <div className=' d-flex justify-content-center align-content-center p-1 m-1'>
      <div className='row justify-content-center vh-100 align-content-center' id="loginform">
        <div className=' col-10'>
          <div className='card border rounded col-12 col-sm-12 col-md-12 col-lg-12 p-4 m-4'>
            <div className="card-header">
              <h2 id="headerTitle">Entrar</h2>
            </div>
            <div className="card-body">
              <div className="row mt-12">
                <label className='fs-4 fw-bold'>Usuario</label>
                <input className='form-control fs-4 fw-bold' type='text' placeholder='Usuario' />
              </div>
              <div className="row mt-12">
                <label className='fs-4 fw-bold'>Contraseña</label>
                <input className='form-control fs-4 fw-bold' type='password' placeholder='Contraseña' />
              </div>

              <div id="button" className="row mt-2">
                <button className='btn btn-primary fs-4 fw-bold'
                  onClick={() => {
                    getUsuario()
                  }}>Entrar</button>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}








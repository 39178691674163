import {
  faCancel,
  faPencil,
  faSave,
  faUserAltSlash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useState } from "react";
import {
  Button,
  Col,
  Container,
  FloatingLabel,
  Form,
  Row,
} from "react-bootstrap";
import UsuarioContext from "../context/usuarios/UsuarioContext";

export const Terapeutas = () => {
  const {
    getTerapeutas,
    terapeutas,
    terapeuta,
    getTerapeuta,
    usuario,
    EditUsuario,
  } = useContext(UsuarioContext);
  const [edatos, setedatos] = useState(true);
  const [bdatos, setbdatos] = useState(true);
  const [gdatos, setgdatos] = useState(true);
  const [cdatos, setcdatos] = useState(true);
  const [editardatos, seteditardatos] = useState(true);
  const [Seleccionado, setSeleccionado] = useState(0);
  const [Elpaciente, setElpaciente] = useState({
    id: "",
    usuario: "",
    password: "",
    nombre: "",
    correo: "",
    telefono: "",
    rol: "",
    estatus: "",
    lunes: "00:00",
    martes: "00:00",
    miercoles: "00:00",
    jueves: "00:00",
    viernes: "00:00",
    sabado: "00:00",
  });

  const resetTerapeuta = () => {
    setElpaciente({
      id: "",
      usuario: "",
      password: "",
      nombre: "",
      correo: "",
      telefono: "",
      rol: "",
      estatus: "",
      lunes: "00:00",
      martes: "00:00",
      miercoles: "00:00",
      jueves: "00:00",
      viernes: "00:00",
      sabado: "00:00",
    })
  };

  const EditDatos = () => {
    seteditardatos(false);
    setedatos(true);
    setbdatos(true);
    setgdatos(false);
    setcdatos(false);
  };
  const   CancelarDatos = () => {
    seteditardatos(true);
    setedatos(false);
    setgdatos(true);
    setcdatos(true);
  };

  const GuardarDatos = () => {
    seteditardatos(true);
    setedatos(false);
    setgdatos(true);
    setcdatos(true);
    EditUsuario(Elpaciente);
  };

  const pacienteCambia = (e) => {
    console.log(e.target.name, e.target.value);
    setElpaciente({ ...Elpaciente, [e.target.name]: e.target.value });
  };
  React.useEffect(() => {
    getTerapeutas(usuario[0].id, usuario[0].rol, 2);
  }, []);

  React.useEffect(() => {
    console.log(terapeuta);
    if (terapeuta.length > 0) {
      setedatos(true)
      CancelarDatos()
      setElpaciente(terapeuta[0]);
      setedatos(false)
    }else{
      CancelarDatos()
      setedatos(true)
    }
  }, [terapeuta]);

  React.useEffect(() => {
    console.log(Seleccionado);
    if (Seleccionado > 0) {
      getTerapeuta(usuario[0].id, usuario[0].rol, Seleccionado);
      seteditardatos(true);
    }else{
      resetTerapeuta()
      setedatos(true)
      setgdatos(true);
      setcdatos(true);
      seteditardatos(true);
    }
  }, [Seleccionado]);
  // console.log(terapeuta)
  // console.log(Elpaciente)
  return (
    <div>
       <div className="d-flex justify-content-center p-0 m-0 pt-5 mt-5">
      <Container>
        <Row className=" justify-content-between">
          <Col
            md={12}
            className=" bg-light border border-secondary rounded-2 p-2 m-0  shadow"
          >
            <Row className="d-flex">
              <Col md={4}>
                <div className="text-muted fs-4 text-center">
                  Datos del Terapeuta
                </div>
              </Col>
              <Col md={4}>
                <Form.Select
                  value={Seleccionado}
                  className=" form-control fs-6 fw-medium p-1 m-1"
                  onChange={(e) => {
                    setSeleccionado(e.target.value);
                  }}
                >
                  <option value={0}>Selecciona Terapeuta</option>
                  {terapeutas.map((elemento) => {
                    return (
                      <option key={elemento.id} value={elemento.id}>
                        {elemento.nombre}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
              <Col md={4}>
                <Row className=" justify-content-between  p-1 m-0">
                  <Col md={12} className="p-1 m-0">
                    <Button
                      className="btn-block w-50 btn-sm p-0"
                      hidden={edatos}
                      onClick={EditDatos}
                    >
                      {" "}
                      <FontAwesomeIcon icon={faPencil} /> Editar
                    </Button>
                 
                    <Button
                      className="btn-block w-50 btn-sm p-0"
                      hidden={gdatos}
                      onClick={GuardarDatos}
                    >
                      {" "}
                      <FontAwesomeIcon icon={faSave} /> Guardar
                    </Button>
                  
                    <Button
                      className="btn-block w-50 btn-sm p-0"
                      hidden={cdatos}
                      onClick={CancelarDatos}
                    >
                      {" "}
                      <FontAwesomeIcon icon={faCancel} /> Cancelar
                    </Button>
                    </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col md={4}>
                <FloatingLabel
                  className="fs-6 fw-bolder p-1 m-1"
                  label="Usuario"
                >
                  <Form.Control
                    className=" form-control fs-6 fw-medium p-1 m-1"
                    name="usuario"
                    value={Elpaciente.usuario}
                    type="text"
                    placeholder="Usuario"
                    onChange={pacienteCambia}
                    readOnly={editardatos}
                  />
                </FloatingLabel>
              </Col>
              <Col md={4}>
                <FloatingLabel
                  className="fs-6 fw-bolder p-1 m-1"
                  label="Password"
                >
                  <Form.Control
                    className=" form-control fs-6 fw-medium p-1 m-1"
                    name="password"
                    value={Elpaciente.password}
                    type="password"
                    placeholder="Password"
                    onChange={pacienteCambia}
                    readOnly={editardatos}
                  />
                </FloatingLabel>
              </Col>
              <Col md={4}>
                <FloatingLabel
                  className="fs-6 fw-bolder p-1 m-1"
                  label="Nombre completo"
                >
                  <Form.Control
                    className=" form-control fs-6 fw-medium p-1 m-1"
                    name="nombre"
                    value={Elpaciente.nombre.toUpperCase()}
                    type="text"
                    minLength={20}
                    placeholder="Nombre completo"
                    onChange={pacienteCambia}
                    readOnly={editardatos}
                  />
                </FloatingLabel>
              </Col>
              <Col md={4}>
                <FloatingLabel
                  className="fs-6 fw-bolder p-1 m-1"
                  label="Correo"
                >
                  <Form.Control
                    className=" form-control fs-6 fw-medium p-1 m-1"
                    name="correo"
                    value={Elpaciente.correo}
                    type="email"
                    placeholder="nombre@dominio.com"
                    onChange={pacienteCambia}
                    minLength={10}
                    readOnly={editardatos}
                  />
                </FloatingLabel>
              </Col>
              <Col md={4}>
                <FloatingLabel
                  className="fs-6 fw-bolder p-1 m-1"
                  label="Teléfono"
                >
                  <Form.Control
                    className=" form-control fs-6 fw-medium p-1 m-1"
                    name="telefono"
                    value={Elpaciente.telefono}
                    type="number"
                    placeholder="##-####-####"
                    onChange={pacienteCambia}
                    minLength={10}
                    readOnly={editardatos}
                  />
                </FloatingLabel>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3" controlId="rol">
                  <FloatingLabel
                    className="fs-6 fw-bolder p-1 m-1"
                    label="Tipo de usuario"
                  >
                    <Form.Select
                      className=" form-control fs-6 fw-medium p-1 m-1"
                      value={Elpaciente.rol}
                      name="rol"
                      onChange={pacienteCambia}
                      disabled={editardatos}
                    >
                      <option value="0">Selecciona Tipo</option>
                      <option value="1">Paciente</option>
                      <option value="2">Terapeuta</option>
                      <option value="3">Administrador</option>
                    </Form.Select>
                  </FloatingLabel>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <label className="fs-5 fw-bold text-center">
                  Horario de comida
                </label>
              </Col>
              <Col md={2}>
                <label className="fs-6 fw-bold text-center">Lunes</label>
                <input
                  type="time"
                  name="lunes"
                  id="lunes"
                  value={Elpaciente.lunes}
                  onChange={pacienteCambia}
                  className="form-control fs-6 fw-bold"
                  readOnly={editardatos}
                />
              </Col>
              <Col md={2}>
                <label className="fs-6 fw-bold text-center">Martes</label>
                <input
                  type="time"
                  name="martes"
                  id="martes"
                  value={Elpaciente.martes}
                  onChange={pacienteCambia}
                  className="form-control fs-6 fw-bold"
                  readOnly={editardatos}
                />
              </Col>
              <Col md={2}>
                <label className="fs-6 fw-bold text-center">Miercoles</label>
                <input
                  type="time"
                  name="miercoles"
                  id="miercoles"
                  value={Elpaciente.miercoles}
                  onChange={pacienteCambia}
                  className="form-control fs-6 fw-bold"
                  readOnly={editardatos}
                />
              </Col>
              <Col md={2}>
                <label className="fs-6 fw-bold text-center">Jueves</label>
                <input
                  type="time"
                  name="jueves"
                  id="jueves"
                  value={Elpaciente.jueves}
                  onChange={pacienteCambia}
                  className="form-control fs-6 fw-bold"
                  readOnly={editardatos}
                />
              </Col>
              <Col md={2}>
                <label className="fs-6 fw-bold text-center">Viernes</label>
                <input
                  type="time"
                  name='viernes'
                  id='viernes'
                  value={Elpaciente.viernes}
                  onChange={pacienteCambia}
                  className="form-control fs-6 fw-bold"
                  readOnly={editardatos}
                />
              </Col>
              <Col md={2}>
                <label className="fs-6 fw-bold text-center">Sábado</label>
                <input
                  type="time"
                  name ='sabado'
                  id='sabado'
                  value={Elpaciente.sabado}
                  onChange={pacienteCambia}
                  className="form-control fs-6 fw-bold"
                  readOnly={editardatos}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      </div>
    </div>
  );
};

import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Menu from "../componentes/menu";
import Calendario from "../pages/calendario";
import Pacientes from "../pages/pacientes";
import Expediente from "../pages/expediente";
import Logout from "../pages/logout";
import Administracion from "../pages/administracion";
import Terapia from "../pages/terapia";
import Terapias from "../pages/terapias";
import { Terapeutas } from "../pages/terapeutas";


export default function AppRouter() {
  return (
    <div>
      <Router>
      <Menu />

      <Routes>
        <Route exact path="/" element={<Calendario />} />
        <Route exact path="/pacientes" element={<Pacientes />} />
        <Route exact path="/administracion" element={<Administracion />} />
        <Route exact path="/terapeutas" element={<Terapeutas />} />
        <Route exact path="/terapias" element={<Terapias />} />
        <Route exact path="/expediente/:id" element={<Expediente />} />
        <Route exact path="/terapia/:id" element={<Terapia />} />
        <Route exact path="/terapias" element={<Terapias />} />
        <Route exact path="/logout" element={<Logout />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
</Router>
    </div>
  );
}

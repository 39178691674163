import React, { useContext } from "react";
import NuevoUsuario from "../componentes/nuevousuario";
import Pacientes from "./pacientes";
import { Breadcrumb, Tab, Tabs } from "react-bootstrap";
import UsuarioContext from "../context/usuarios/UsuarioContext";
import { Terapeutas } from "./terapeutas";
import { MDBBreadcrumb, MDBCol, MDBRow } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import Terapias from "./terapias";

export default function Administracion() {
const {setPS,setDS,getSesiones} = useContext(UsuarioContext)

React.useEffect(() => {
  setPS(0)
  setDS(0)
},[])


  return (
    <div className="d-flex justify-content-center  m-1 p-1 pt-5 mt-5 ">

      <section className="col-md-12">
        <Tabs
          defaultActiveKey="usuarionuevo"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="usuarionuevo" title="Nuevo Usuario">
            <NuevoUsuario />
          </Tab>
        </Tabs>

     
      </section>
    </div>
  );
}

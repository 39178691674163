import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import {
  faUser,
  faCalendar,
  faPowerOff,
  faUserPlus,
  faUserNurse,
  faScrewdriverWrench,
  faPersonWalking,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Navbar, Nav } from "react-bootstrap";

import UsuarioContext from "../context/usuarios/UsuarioContext";

export default function Menu() {
  const { usuario, setUsuario } = useContext(UsuarioContext);

  function salir() {
    localStorage.setItem("log", JSON.stringify([]));
    setUsuario([]);
  }

  if (usuario.length > 0) {
    return (
      <div>


        <Navbar
          collapseOnSelect
          fixed="top"
          expand="lg"
          bg="warning"
          variant="warning"
          border="dark"
          className="p-1"
          
        >
        
          <Navbar.Brand href="#" className="logo">
          <span className="fs-6 fw-semibold py-2 ">{usuario[0].nombre}</span>
          </Navbar.Brand>
          
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="container-fluid">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <NavLink
                    className={({ isActive }) =>
                      isActive
                        ? "active  nav-link fw-bolder fs-6"
                        : "nav-link fs-6 fw-semibold"
                    }
                    to="/"
                  >
                    <FontAwesomeIcon icon={faCalendar} /> Agenda
                  </NavLink>
                </li>

               
                <li className="nav-item">
                  <NavLink
                    className={({ isActive, isPending }) =>
                      isActive
                        ? "active  nav-link fw-bolder fs-6"
                        : "nav-link fs-6 fw-semibold"
                    }
                    to="/administracion"
                  >
                    <FontAwesomeIcon icon={faScrewdriverWrench} /> Administración
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={({ isActive, isPending }) =>
                      isActive
                        ? "active  nav-link fw-bolder fs-6"
                        : "nav-link fs-6 fw-semibold"
                    }
                    to="/pacientes"
                  >
                    <FontAwesomeIcon icon={faUser} /> Pacientes
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={({ isActive, isPending }) =>
                      isActive
                        ? "active  nav-link fw-bolder fs-6"
                        : "nav-link fs-6 fw-semibold"
                    }
                    to="/terapeutas"
                  >
                    <FontAwesomeIcon icon={faUserNurse} /> Terapeutas
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={({ isActive, isPending }) =>
                      isActive
                        ? "active  nav-link fw-bolder fs-6"
                        : "nav-link fs-6 fw-semibold"
                    }
                    to="/terapias"
                  >
                    <FontAwesomeIcon icon={faPersonWalking} /> Terapias
                  </NavLink>
                </li>
              </ul>
              <div className="d-flex align-content-end justify-content-end">
             
                <button
                  className=" btn fw-bold fs-5 bg-transparent"
                  onClick={(e) => salir()}
                >
                  <FontAwesomeIcon icon={faPowerOff} />
                </button>
              </div>
            </Nav>
          </Navbar.Collapse>
  
        </Navbar>
      </div>
    );
  } else {
    <div></div>;
  }
}
